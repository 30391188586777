import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p>The new home of your favorite porn site</p>
        <span className='emoji' role='img' aria-label='smiling face with horns'></span>
      </header>
    </div>
  );
}

export default App;
